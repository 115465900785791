import axios from 'axios';
// import Vue from 'vue';


// import { Message } from 'element-ui';

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: process.env.NODE_ENV === 'development',
    timeout: 35000
});


service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);

        // Message.error('服务端出错');
        return Promise.reject();
    }
);

export default service;
