import request from '../utils/request';

export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'AccountSetup/find',
        method: 'get',
        params: query
    });
};

