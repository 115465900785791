import request from '../utils/request';



//根据角色生成匹配树
export const roleData = query => {
    return request({
        url: process.env.VUE_APP_URL+'Sidebar/find',
        method: 'get',
        params: query
    });
};