import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import ("../views/Dashboard.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import ('../views/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import ('../views/403.vue')
            }, {
                path: '/User/index',
                meta: {
                    title: '用户管理'
                },
                component: () => import ('../views/User/index')
            }, {
                path: '/ProjectAdmin/NumberAdmin',
                name: '项目查看与发起',
                meta: {
                    title: '项目查看与发起'
                },
                component: () => import ('../views/ProjectAdmin/NumberAdmin')
            }, {
                path: '/ProjectAdmin/Audit',
                name: '项目发起审核',
                meta: {
                    title: '项目发起审核'
                },
                component: () => import ('../views/ProjectAdmin/Audit')
            },{
                path: '/ProjectAdmin/Cpproval',
                name: '项目发起确认',
                meta: {
                    title: '项目发起确认'
                },
                component: () => import ('../views/ProjectAdmin/Cpproval')
            },{
                path: '/ProjectAdmin/Type',
                name: '贡献值标签管理',
                meta: {
                    title: '贡献值标签管理'
                },
                component: () => import ('../views/ProjectAdmin/Type')
            },{
                path: '/ProjectAdmin/Committee',
                name: '委员会成员管理',
                meta: {
                    title: '委员会成员管理'
                },
                component: () => import ('../views/ProjectAdmin/Committee')
            },{
                path: '/ProjectAdmin/Finance',
                name: '财务成员管理',
                meta: {
                    title: '财务成员管理'
                },
                component: () => import ('../views/ProjectAdmin/Finance')
            },{
                path: '/Integral/Distbatch',
                name: '贡献值批量分配',
                meta: {
                    title: '贡献值批量分配'
                },
                component: () => import ('../views/Integral/Distbatch')
            },{
                path: '/Integral/Audit',
                name: '贡献值审核',
                meta: {
                    title: '贡献值审核'
                },
                component: () => import ('../views/Integral/Audit')
            },{
                path: '/Integral/Cpproval',
                name: '贡献值确认管理',
                meta: {
                    title: '贡献值确认管理'
                },
                component: () => import ('../views/Integral/Cpproval')
            },{
                path: '/Bonus/Audit',
                name: '奖金审核管理',
                meta: {
                    title: '奖金审核管理'
                },
                component: () => import ('../views/Bonus/Audit')
            },{
                path: '/Bonus/Cpproval',
                name: '奖金确认管理',
                meta: {
                    title: '奖金确认管理'
                },
                component: () => import ('../views/Bonus/Cpproval')
            },
            {
                path: '/Sign/index',
                meta: {
                    title: '个人账号管理'
                },
                component: () => import ('../views/Sign/index')
            },  {
                path: '/Sign/mech',
                meta: {
                    title: '机构账号管理'
                },
                component: () => import ('../views/Sign/mech')
            },
            {
                path: '/Contract/init',
                meta: {
                    title: '经办合同'
                },
                component: () => import ('../views/Contract/index')
            },
            {
                path: '/LogAdmin/operate',
                meta: {
                    title: '操作记录'
                },
                component: () => import ('../views/LogAdmin/Operate')
            },
            {
                path: '/LogAdmin/user',
                meta: {
                    title: '用户贡献值记录'
                },
                component: () => import ('../views/LogAdmin/User')
            },
            {
                path: '/LogAdmin/Bonus',
                meta: {
                    title: '用户奖金记录'
                },
                component: () => import ('../views/LogAdmin/Bonus')
            },
            {
                path: '/System/user',
                meta: {
                    title: '后台管理人员'
                },
                component: () => import ('../views/System/User')
            },
            {
                path: '/System/permission',
                meta: {
                    title: '权限管理'
                },
                component: () => import ('../views/System/Permission')
            },
            {
                path: '/System/resource',
                meta: {
                    title: '资源管理'
                },
                component: () => import ('../views/System/Resource')
            },
            {
                path: '/Admin/Settings',
                meta: {
                    title: '账号设置'
                },
                component: () => import ('../views/Admin/Settings/index')
            },
            {
                path: '/System/sign',
                meta: {
                    title: 'E签宝设置'
                },
                component: () => import ('../views/System/Sign')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
            "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const role = localStorage.getItem('User_id');
    // this.$router.push("/");
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;
