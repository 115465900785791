import request from '../utils/request';

export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/user/login',
        method: 'post',
        data: query
    });
};

export const fetchUser_id = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Admin/user/User_id',
        method: 'get',
        params: query
    });
};

