<template>
  <router-view/>
</template>

<script>
import {fetchUser_id} from "./api/Login";

export default {

  created() {
    // 页面每次刷新加载时候都会去读取sessionStorage里面的vuex状态
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store")) //这里存的是可能经过mutions处理过的state值，是最新的,所以放在最后
          )
      )
    }
    // 在页面刷新之前把vuex中的信息存到sessionStoreage
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    const EffTime = localStorage.getItem('EffTime');
    const User_id = localStorage.getItem('User_id');
    if (User_id && EffTime) {
      fetchUser_id({User_id}).then(res => {
        if (res.code == 0) {
          let sum = res.sum
          this.$store.state.UserInfo = sum
        } else {
          localStorage.removeItem("User_id");
          localStorage.removeItem("EffTime");
          this.$router.push("/login");
        }
      })
    } else {
      localStorage.removeItem("User_id");
      localStorage.removeItem("EffTime");
      this.$router.push("/");
    }
  },
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";

</style>
